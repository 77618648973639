import React from 'react';
import './style.scss';

const OptionsDropdown = ({ isVisible , options }) => {
  return (
    isVisible ? <div className="Options-Dropdown">
      {
        options.map(ele => { 
          return <div 
            className="Options-Dropdown__items" 
            onClick={ele.clickHandler}>
            {ele.text}
          </div>
        })
      }
    </div> : <></>
  )
}

export default OptionsDropdown;